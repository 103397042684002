<template>
  <div class="profile-page">
    <div class="wrap-title">
      <div class="back" @click="back"> Назад</div>
      <div class="title">Пользовательское Соглашение</div>
    </div>
    <div class="body">
      1. Общие положения<br>
      <br>
      1.1. Настоящее пользовательское соглашение (далее – Соглашение) регулирует отношения между ТОО «Тайм Трекер» (далее – Компания) и всех посетителей и пользователей Приложения «TTracker» (далее – Приложение).<br>
      1.2. Соглашение может быть изменено Компанией без какого-либо специального уведомления, и новая редакция Соглашения вступает в силу с момента ее размещения в Приложении.<br>
      1.3. Начиная использовать материалы Приложения, его отдельные функции, либо пройдя процедуру регистрации, Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В случае нарушения настоящего Соглашения, Компания оставляет за собой право применить все допустимые законодательством Республики Казахстан средства по отношению к нарушителям.<br>
      1.4. Компания содержит Приложение для предоставления Пользователю возможности осуществлять фиксацию начала и окончания собственного рабочего времени по договору с работодателем Пользователя через использование Кода.<br>
      1.5. Компания не несет никакой ответственности за любые ошибки, опечатки и неточности, которые могут быть обнаружены в материалах, содержащихся в Приложении. Компания прилагает все необходимые усилия, чтобы обеспечить точность и достоверность представляемой в Приложении информации. Информация в Приложении постоянно обновляется и в любой момент может оказаться не актуальной. Таким образом, Компания не несет ответственности или обязательств любого рода за информацию, размещённую в Приложении. Компания оставляет за собой право удалять размещенную в Приложении информацию и предпринимать иные меры для прекращения доступа к Приложению отдельных Пользователей.<br>
      1.6. Пользователь заявляет и гарантирует, что он ознакомлен с условиями и положениями настоящего Соглашения, что настоящее Соглашение ему понятно и не содержит невыгодных и/или обременительных условий, а также исходя из разумного интереса Пользователь не принял бы при наличии у него возможности использования настоящего Соглашения.<br>
      2. Термины и определения<br>
      <br>
      2.1. В Соглашении используются термины и определения в следующих значениях:<br>
      <br>
      2.1.1. Личный кабинет – закрытая от публичного доступа часть Приложения, доступ Пользователя к которой осуществляется с использованием Учетной записи.<br>
      2.1.2. Учетная запись Пользователя – совокупность идентификационных данных о Пользователе, зафиксированных в цифровом формате и необходимая для предоставления доступа к его личным данным, настройкам и сервису Приложения.<br>
      2.1.3. Регистрация – создание Личного кабинета Пользователя с указанием идентификационных данных.<br>
      2.1.4. Пользователь – физическое лицо, посетитель и пользователь Приложения, состоящие в трудовых отношениях с Работодателем.<br>
      2.1.5. Код – созданный Компанией QR-код, размещенный в установленном месте у Работодателя, считываемый Пользователем через Приложение по месту выполнения им своей трудовой функции у Работодателя при приходе на работу и уходе с работы.<br>
      2.1.6. Работодатель – юридическое лицо, индивидуальный предприниматель состоящее в трудовых отношениях с Пользователем и заключившее с Компанией Лицензионный договор.<br>
      2.1.7. Лицензионный договор – договор, заключенный между Работодателем и Компанией, в соответствии с которым Компания предоставляет Работодателю право использования программного обеспечения, предназначенного для учета рабочего времени работников Работодателя, в том числе посредством считывания Пользователем Кода.<br>
      3. Регистрация Пользователя<br>
      <br>
      3.1. При регистрации Пользователь обязуется предоставить Компании полную и достоверную информацию о себе по вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии.<br>
      3.2. Пользователь самостоятельно несет ответственность за безопасность (устойчивость к угадыванию или подбору каким-либо методом) выбранного им пароля, а также самостоятельно обеспечивает конфиденциальность своего пароля. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием Личного кабинета под учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия в рамках или с использованием Личного кабинета под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением случаев, когда Пользователь, в порядке, предусмотренном пунктом 3.3 настоящего Соглашения, уведомил Компанию о несанкционированном доступе к Личному кабинету с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля.<br>
      3.3. Пользователь обязан немедленно уведомить Компанию о любом случае несанкционированного (не разрешенного Пользователем) доступа к Личному кабинету с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля. В целях безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей учетной записью по окончании каждой сессии работы с Личным кабинетом. Компания не отвечает за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем этой части Соглашения.<br>
      3.4. Компания вправе направлять Пользователю информационные сообщения включая рассылку посредством СМС сообщений, сообщений на электронную почту Пользователя и иные способы отправления материалов рассылки. После регистрации Пользователь по умолчанию подписан на рассылку.<br>
      4. Применение Кода<br>
      <br>
      4.1. Пользователь самостоятельно устанавливает на своем мобильном устройстве Приложение и изучает его функционал.<br>
      4.2. С установленной Работодателем даты Пользователь осуществляет применение Кода для целей учета своего рабочего времени.<br>
      4.3. Компания не несет ответственности за правильность использования Пользователем Кода, а также за сам факт такого использования.<br>
      4.4. Любые споры и разногласия между Пользователем и Работодателем по вопросам надлежащего учета рабочего времени рассматриваются без участия Компании. Если нарушение в работе программного обеспечения, предоставленного Работодателю Компанией, произойдет по причинам, зависящим от Компании, то Компания самостоятельно урегулирует данный вопрос с Работодателем.<br>
      4.5. В случае, если в необходимый момент для считывания Кода Пользователь не в состоянии воспользоваться Приложением по техническим причинам, он обязан самостоятельно сообщить об этом Работодателю.<br>
      5. Конфиденциальность и обработка персональных данных<br>
      <br>
      5.1. В то время как, Пользователь просматривает, читают и скачивает контент Приложения, происходит автоматический сбор информации о посетителях для статистических целей (т.е. IP-адрес, имя домена, тип браузера и операционной системы, дата и время посещения и т. д.). Подразумевается, что эта информация является общедоступной. Компания не несет ответственности или каких-либо обязательств за ее разглашение. Компания осуществляет сбор и обработку только тех персональных данных, которые Пользователь предоставляет добровольно путем заполнения соответствующих разделов Приложения.<br>
      5.2. Завершение процедуры регистрации Пользователем означает безоговорочное его согласие с указанными в настоящем Соглашении условиями обработки персональной информации Пользователя; в случае несогласия с этими условиями Пользователь должен воздержаться от использования процедуры регистрации.<br>
      5.3. Пользователь разрешает Компании обрабатывать свои персональные данные, а именно фамилию, имя, отчество, ИИН, дату рождения, пол, почтовый адрес, контактные телефоны, адрес электронной почты, адрес проживания и иную информацию, требуемые для исполнения Лицензионного договора, включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передачу на территории Республики Казахстан и трансграничную передачу), обезличивание, блокирование, уничтожение персональных данных, для проведения опросов и исследований, направленных на улучшение качества услуг, для проведения маркетинговых программ, статистических исследований. Пользователь настоящим выражает согласие и разрешает Компании обрабатывать свои персональные данные, а также для направления Пользователю поздравлений с праздниками путем осуществления прямых контактов с Пользователем помощью различных средств связи, включая, но, не ограничиваясь: почтовая рассылка, электронная почта, телефон (включая мобильный), факсимильная связь, сеть Интернет.<br>
      5.4. Пользователь соглашается с тем, что, если это необходимо для реализации целей, указанных в Договоре на оказание услуг, его персональные данные, полученные Компанией, могут быть переданы третьим лицам, которым Компания может поручить обработку персональных данных Пользователя на основании договора, заключенного с такими лицами, при условии соблюдения требований законодательства РК об обеспечении конфиденциальности персональных данных и безопасности персональных данных при их обработке.<br>
      5.5. Согласие, данное Пользователем на обработку его персональных данных, может быть отозвано посредством направления Компании письменных заявлений. Согласие Пользователя на обработку его персональных данных считается отозванным по истечении 10 (Десяти) рабочих дней со дня получения заявления Компанией, если более поздний срок не указан в заявлении.<br>
      5.6. Согласие, выдаваемое Пользователем, в соответствии с настоящим разделом, выдается на срок 10 (десять) лет.<br>
      6. Авторское право<br>
      <br>
      6.1. Вся информация Приложения защищена законом Республики Казахстан об авторских и смежных правах. В связи с этим, любое использование (как в личных, так и коммерческих целях) информации и материалов с Приложения не может считаться законным без наличия разрешения Компании.<br>
      7. Прочие условия.<br>
      <br>
      7.1. Настоящее Соглашение и все отношения, возникающие в процессе использования Приложения, регулируются законодательством Республики Казахстан.<br>
      7.2. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Республики Казахстан. В случае возникновения разногласий и споров между Сторонами по вопросам, предусмотренным настоящим Соглашением или в связи с ним, Стороны примут все меры к разрешению их путем переговоров в претензионном порядке. Спор подлежит окончательному разрешению в суде по месту нахождения Компании.<br>
      7.3. Настоящее Соглашение вступает в силу с момента нажатия Пользователем кнопки «Я согласен (на) с условиями Пользовательского соглашения».<br>
      7.4. Реквизиты Компании:<br>
      <br>
      ТОО «Тайм Трекер»<br>
      Адрес:Республика Казахстан,<br>
      г. Алматы, Самал-2, Бектурова 104, 502 офис<br>
      БИН: 231240011993<br>
      Банковские реквизиты:<br>
      Банк: АО «БанкЦентрКредит»<br>
      ИИК: KZ598562203135144066<br>
      БИК: KCJBKZKX<br>
      E-mail (для обмена бухгалтерскими<br>
      документами): info@timetracker.kz<br>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PageIndex',
  data() {
    return {
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'register', })
    },
  },
}
</script>

<style scoped lang="scss">
.profile-page {
  display: flex;
  flex-direction: column;
  .body {
    margin-top: 10px;
    padding: 15px;
    text-align: justify;
  }
  .wrap-title{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    .back {
      position: absolute;
      top: calc(env(safe-area-inset-top) + 10px);
      left: 14px;
      color: var(--primary);
    }
    padding-top: calc( env(safe-area-inset-top) + 20px);
    padding-bottom: 5px;
  }

  .title {
    margin-top: 20px;
    text-align: center;
    color: black;
    font-size: 15px;
    font-weight: 700;
  }
  .navigation {
    margin-top: 20px;
    .item {
      .left-side {
        display: flex;
        align-items: center;
        font-weight: bold;
        .n-icon {
          margin-right: 10px;
        }
      }
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;

    .avatar {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 10px;
    }

    .name{
      font-size: 15px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .user-data{
    .item{
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }

      .label{
        color: #959494;
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 10px;
        height: 40px;
        background-color: #F5F5FA;
        box-shadow: -5px -5px 10px rgba(255, 255, 255, 0.5), 5px 5px 10px rgba(170, 170, 204, 0.25),
        10px 10px 20px rgba(170, 170, 204, 0.5), -10px -10px 20px #FFFFFF;
        border-radius: 16px;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: underline;
    padding: 0 10px;
    .profile-button {
      color: #686868;
      &.danger {
        color: #FF4B33;
      }
    }
  }
}
</style>
